import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
import Landing from "../pages/landing";
import ProtectedRoute from "./ProtectedRoute";
import Loader from "../components/Loader";
import useCustomerStore from "../stores/useRefreshStore";

const Splash = React.lazy(() => import("../pages/splash"));
const Login = React.lazy(() => import("../pages/login"));
const Scan = React.lazy(() => import("../pages/scan"));
const PrivacyPolicy = React.lazy(() => import("../pages/privacyPolicy"));
const Contact = React.lazy(() => import("../pages/contact"));
const ContactNew = React.lazy(() => import("../pages/contactNew"));
const TermsConditions = React.lazy(() => import("../pages/termsConditions"));
const LicenseScanner = React.lazy(() => import("../pages/licenseScanner"));
const PassportScanner = React.lazy(() => import("../pages/passportScanner"));
const Phone = React.lazy(() => import("../pages/phone"));
const DashboardTransation = React.lazy(() =>
  import("../pages/dashboardTransation"),
);
const Dashboard = React.lazy(() => import("../pages/dashboard"));
const QrCodePage = React.lazy(() => import("../pages/qrCode"));
const QRCodeExample = React.lazy(() => import("../pages/QRCodeExample"));
const TwoFactor = React.lazy(() => import("../pages/two-factor"));
const Confirmation = React.lazy(() => import("../pages/confirmation"));
const TwoFactorLogin = React.lazy(() => import("../pages/2fa-pin"));
const WithdrawalRequestList = React.lazy(() =>
  import("../pages/withdrawalRequest"),
);
const BankList = React.lazy(() => import("../pages/bankList"));
const VerifyBank = React.lazy(() => import("../pages/verifyBank"));
const GoodsServices = React.lazy(() => import("../pages/goods-services"));
const FriendsAndFamily = React.lazy(() => import("../pages/friendsAndFamily"));
const LoadFund = React.lazy(() => import("../pages/load-fund"));
const Activity = React.lazy(() => import("../pages/activity"));
const FundRequests = React.lazy(() => import("../pages/fundRequests"));
const CompleteTransfer = React.lazy(() => import("../pages/completeTransfer"));
const AddFundingMethod = React.lazy(() => import("../pages/AddFundingMethod"));
const Request = React.lazy(() => import("../pages/request"));
const Payment = React.lazy(() => import("../pages/payment"));
const OnlyMobile = React.lazy(() => import("../components/OnlyMobile"));
const Invoice = React.lazy(() => import("../components/MainReceipt"));
const ESign = React.lazy(() => import("../pages/eSign"));

function AppRouter() {
  const { customer } = useCustomerStore();

  return (
    <>
      <Routes>
        <Route path={"/"} element={<Landing />} />
        <Route
          path="/splash"
          element={
            <Suspense fallback={<Loader />}>
              <Splash />
            </Suspense>
          }
        />

        <Route path={"/receipt/:memoid?"} element={<Invoice />} />
        <Route
          path="/privacy-policy"
          element={
            <Suspense fallback={<Loader />}>
              <PrivacyPolicy />
            </Suspense>
          }
        />
        <Route
          path="/contact"
          element={
            <Suspense fallback={<Loader />}>
              <ContactNew />
            </Suspense>
          }
        />
        <Route
          path="/contact-new"
          element={
            <Suspense fallback={<Loader />}>
              <Contact />
            </Suspense>
          }
        />
        <Route
          path="/terms-conditions"
          element={
            <Suspense fallback={<Loader />}>
              <TermsConditions />
            </Suspense>
          }
        />
        <Route path={"/e-sign"} element={<ESign />} />
        {isMobile && !isTablet && (
          <>
            <Route
              path="/login"
              element={
                <Suspense fallback={<Loader />}>
                  <Login />
                </Suspense>
              }
            />
            <Route
              path="/scan/:contactlessId?"
              element={
                <Suspense fallback={<Loader />}>
                  <Scan p2p={false} />
                </Suspense>
              }
            />
            <Route
              path={"/p2p/:p2pId?"}
              element={
                <Suspense fallback={<Loader />}>
                  <Scan p2p={true} />
                </Suspense>
              }
            />
            <Route
              path={"/p2p-qr/:customerExtId"}
              element={
                <Suspense fallback={<Loader />}>
                  <Scan />
                </Suspense>
              }
            />
            <Route
              path={"qrpay/:storeExternalId"}
              element={
                <Suspense fallback={<Loader />}>
                  <Scan p2p={false} qr={true} />
                </Suspense>
              }
            />
            <Route
              path={"qr-license-scanner/:storeExternalId"}
              element={
                <Suspense fallback={<Loader />}>
                  <LicenseScanner p2p={false} qr={true} />
                </Suspense>
              }
            />
            <Route
              path={"/license-scanner/:contactlessId?"}
              element={
                <Suspense fallback={<Loader />}>
                  <LicenseScanner p2p={false} />
                </Suspense>
              }
            />
            <Route
              path={"/p2p-license-scanner/:p2pId?"}
              element={
                <Suspense fallback={<Loader />}>
                  <LicenseScanner p2p={true} />
                </Suspense>
              }
            />
            <Route
              path={"/p2p-qr-license-scanner/:customerExtId"}
              element={
                <Suspense fallback={<Loader />}>
                  <LicenseScanner />
                </Suspense>
              }
            />
            <Route
              path={"/p2p-qr-passport-scanner/:customerExtId"}
              element={
                <Suspense fallback={<Loader />}>
                  <PassportScanner />
                </Suspense>
              }
            />
            <Route
              path={"/passport-scanner/:contactlessId?"}
              element={
                <Suspense fallback={<Loader />}>
                  <PassportScanner />
                </Suspense>
              }
            />
            <Route
              path={"/p2p-passport-scanner/:p2pId?"}
              element={
                <Suspense fallback={<Loader />}>
                  <PassportScanner />
                </Suspense>
              }
            />
            <Route
              path={"/qr-passport-scanner/:storeExternalId?"}
              element={
                <Suspense fallback={<Loader />}>
                  <PassportScanner />
                </Suspense>
              }
            />
            <Route
              path={"/verify-phone/:custExtId?"}
              element={
                <Suspense fallback={<Loader />}>
                  <Phone />
                </Suspense>
              }
            />
            <Route element={<ProtectedRoute />}>
              <Route
                path={"/account-details"}
                element={
                  <Suspense fallback={<Loader />}>
                    <DashboardTransation />
                  </Suspense>
                }
              />
              <Route
                path={"/dashboard/:p2pId?"}
                element={
                  <Suspense fallback={<Loader />}>
                    <Dashboard />
                  </Suspense>
                }
              />
              <Route
                path={"/qrCode"}
                element={
                  <Suspense fallback={<Loader />}>
                    <QrCodePage />
                  </Suspense>
                }
              />
              <Route
                path={"/qr"}
                element={
                  <Suspense fallback={<Loader />}>
                    <QRCodeExample />
                  </Suspense>
                }
              />
              <Route
                path={"/two-factor"}
                element={
                  <Suspense fallback={<Loader />}>
                    <TwoFactor />
                  </Suspense>
                }
              />
              <Route
                path={"/confirmation"}
                element={
                  <Suspense fallback={<Loader />}>
                    <Confirmation />
                  </Suspense>
                }
              />
              <Route
                path={"/pin-login/:type?"}
                element={
                  <Suspense fallback={<Loader />}>
                    <TwoFactorLogin />
                  </Suspense>
                }
              />
              <Route
                path={"/withdrawal-list"}
                element={
                  <Suspense fallback={<Loader />}>
                    <WithdrawalRequestList customer={customer} />
                  </Suspense>
                }
              />
              <Route
                path={"/withdrawal"}
                element={
                  <Suspense fallback={<Loader />}>
                    <BankList />
                  </Suspense>
                }
              />
              <Route
                path={"/verify-bank"}
                element={
                  <Suspense fallback={<Loader />}>
                    <VerifyBank />
                  </Suspense>
                }
              />
              <Route
                path={"/goods-services/:externalId?/:custIdForDoobie?"}
                element={
                  <Suspense fallback={<Loader />}>
                    <GoodsServices />
                  </Suspense>
                }
              />
              <Route
                path={"/goods-services-qr/:storeExternalId"}
                element={
                  <Suspense fallback={<Loader />}>
                    <GoodsServices />
                  </Suspense>
                }
              />
              <Route
                path={"/transfer-money/:customerId?"}
                element={
                  <Suspense fallback={<Loader />}>
                    <FriendsAndFamily />
                  </Suspense>
                }
              />
              <Route
                path={"/payment"}
                element={
                  <Suspense fallback={<Loader />}>
                    <Payment />
                  </Suspense>
                }
              />
              <Route
                path={"/deposit-funds"}
                element={
                  <Suspense fallback={<Loader />}>
                    <LoadFund />
                  </Suspense>
                }
              />
              <Route
                path={"/activity"}
                element={
                  <Suspense fallback={<Loader />}>
                    <Activity />
                  </Suspense>
                }
              />
              <Route
                path={"/requests"}
                element={
                  <Suspense fallback={<Loader />}>
                    <Request />
                  </Suspense>
                }
              />
              <Route
                path={"/fund-request/:extId"}
                element={
                  <Suspense fallback={<Loader />}>
                    <FundRequests />
                  </Suspense>
                }
              />
              <Route
                path={"/complete-transfer"}
                element={
                  <Suspense fallback={<Loader />}>
                    <CompleteTransfer />
                  </Suspense>
                }
              />
              <Route
                path={"/add-funding-method"}
                element={
                  <Suspense fallback={<Loader />}>
                    <AddFundingMethod />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path="/"
              element={
                <Navigate
                  to={
                    useCustomerStore.getState().customer
                      ? "/dashboard"
                      : "/login"
                  }
                  replace
                />
              }
            />
          </>
        )}
        <Route path={"*"} element={<OnlyMobile />} exct={true} />
      </Routes>
    </>
  );
}

export default AppRouter;
